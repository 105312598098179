body {
    --ion-toolbar-background: var(--ion-color-primary);
    --ion-toolbar-background-rgb: var(--ion-color-primary-rgb);

    --ion-toolbar-color: var(--ion-color-primary-contrast);
    --ion-toolbar-color-rgb: var(--ion-color-primary-contrast-rgb);
}

body > ion-title {
    --ion-text-color: var(--ion-color-primary-contrast);
    --ion-text-color-rgb: var(--ion-color-primary-contrast-rgb);
}

ion-header {
    background: var(--ion-color-primary);
}

.header-collapse-condense ion-toolbar {
    --background: var(--ion-color-primary);
    --color: var(--ion-color-primary-contrast);
}
.program-alert {
    --min-width: 90%;
}

.date-input {
    width: 48% !important;
    float: left;
}

.end-date-input {
    margin-left: 11px !important;
}

.read-only-input {
    opacity: 1 !important;
    border: none !important;
    color: black;
    background: var(--background) !important;
    padding-bottom: 0 !important;
    margin-bottom: -4px !important;
}
